.actionDrawerWrap {
  width: 50%;
  padding: 24px;
  background-color: white;
}

.actionDrawerHeader {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.actionDrawerDescr {
  margin: 16px 0 16px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}

.blockTag {
  margin-top: 4px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 4px;
  border: none;
  background: #F0F1F3;
}